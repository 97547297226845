/* App.css */
body {
  background-color: #f3f4f6; /* Lighter and more modern background color */
  font-family: "Arial", sans-serif; /* Sleek font */
  color: #333; /* Dark grey text for better readability */
}

header {
  background-color: #1a73e8; /* Deep blue for a modern look */
  padding: 15px;
  text-align: center;
  color: white; /* White text for contrast */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.card {
  background-color: white;
  margin: 15px;
  padding: 25px;
  border-radius: 8px; /* Increased radius for modern rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for elevation */
}

button {
  background-color: #1a73e8; /* Matching button color with header */
  color: white; /* White text for buttons */
  border: none;
  padding: 12px 25px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for button interaction */
}

button:hover {
  background-color: #1669c7; /* Slightly darker shade on hover */
}

/* Additional global styles can be added as needed */
