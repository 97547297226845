.password-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-form {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.password-input-group {
  display: flex;
  align-items: center;
}
